"use client";
import { Header } from "@submix/next-components";
import { useTranslation } from "../i18n/client";
import type { Session } from "next-auth";
import { useState, useEffect } from "react";
import { Trans } from "react-i18next";

const ClientHeaderWrapper = ({
  session,
  lang,
  basePath,
}: {
  session: Session | null;
  lang: string;
  basePath: string;
}) => {
  const { t } = useTranslation(lang, "common");

  const [clientActiveSubscription, setClientActiveSubscription] =
    useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getActiveSubscription = async () => {
      setIsLoading(true);
      const response = await fetch(`/api/subscription`).then(
        async (t) => await t.json(),
      );

      if (response.data) {
        setClientActiveSubscription(response.data);
      }
      setIsLoading(false);
    };

    getActiveSubscription();
  }, []);

  return (
    <Header
      translation={t}
      session={session}
      hasActiveSubscription={Boolean(clientActiveSubscription)}
      plan={{
        planCode:
          clientActiveSubscription?.plan_code ||
          clientActiveSubscription?.lago?.plan_code,
        planCreatedAt: clientActiveSubscription?.lago?.created_at,
        trialPeriod: clientActiveSubscription?.lago?.plan?.trial_period,
      }}
      basePath={basePath}
      isLoadingPlanCode={isLoading}
      Trans={Trans}
    />
  );
};

export default ClientHeaderWrapper;
