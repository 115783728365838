const getDaysFromMS = (valueInMS: number) => valueInMS / 1000 / 60 / 60 / 24;

const dayToMS = 24 * 60 * 60 * 1000;

export const getFreeTrialRemainingDays = (
  trialStartedAt?: string,
  trialPeriod?: number,
) => {
  if (trialPeriod) {
    const currentDateInMS = new Date().getTime();

    const planCreatedAtInMS = trialStartedAt
      ? new Date(trialStartedAt).getTime()
      : currentDateInMS;

    const trialPeriodInMS = dayToMS * trialPeriod;
    const trialExpirationInMS = planCreatedAtInMS + trialPeriodInMS;

    const freeTrialRemainingDays = getDaysFromMS(
      trialExpirationInMS - currentDateInMS,
    );

    return freeTrialRemainingDays;
  }
  return 0;
};
