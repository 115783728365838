import { Avatar, Typography, getTierIcon } from "@submix/component-library";
import type { Session } from "next-auth";
import Link from "next/link";
import React from "react";
import classNames from "classnames";

export type MyAccountProps = {
  session: Session | null;
  hasActiveSubscription: boolean;
  className?: string;
  basePath: string;
  planCode: string;
  isLoadingPlanCode: boolean;
};

const MyAccount: React.FC<MyAccountProps> = ({
  session,
  hasActiveSubscription,
  planCode,
  className,
  basePath,
  isLoadingPlanCode,
}) => {
  if (!session) {
    return null;
  }

  const userName = session?.user?.name;

  const icon = getTierIcon(planCode, "100%", "100%");

  return (
    <>
      <Link
        href={`${basePath}/account/subscription`}
        className={classNames(
          className,
          "relative flex max-w-[calc(100%-34px)] items-center justify-end gap-1.5 md:max-w-full",
        )}
      >
        {hasActiveSubscription && !isLoadingPlanCode && (
          <div className="h-5 w-5">{icon}</div>
        )}
        {isLoadingPlanCode && (
          <div className="h-5 w-5 animate-pulse rounded-full bg-neutral-800"></div>
        )}
        <Typography.Body
          onDark
          bold
          className="!text-sm max-md:truncate md:min-w-fit"
        >
          {userName}
        </Typography.Body>
        <Avatar
          ownerName={userName || "Unknown"}
          className="h-8 min-w-8 [&>span]:!text-base"
        />
      </Link>
    </>
  );
};

export default MyAccount;
