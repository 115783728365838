import { BaseSvgProps } from "./types";
const SvgLinkedinIconSmall = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M4.653 6.662H.58v12.994h4.074V6.662ZM15.675 6.38c-.15-.018-.31-.027-.469-.037a4.755 4.755 0 0 0-4.018 1.84c-.122.16-.179.254-.179.254V6.7H7.113v12.994h4.075v-6.102c0-.883-.066-1.822.375-2.63.376-.676 1.052-1.014 1.812-1.014 2.253 0 2.3 2.038 2.3 2.225v7.577h4.075v-8.478c0-2.9-1.474-4.61-4.075-4.891ZM2.616 4.982a2.366 2.366 0 1 0 0-4.732 2.366 2.366 0 0 0 0 4.732Z"
      fill="#fff"
    />
  </svg>
);
export default SvgLinkedinIconSmall;
