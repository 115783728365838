import React from "react";
import Link from "next/link";
import classnames from "classnames";
import { Button } from "@submix/component-library";

type NavLinksProps = {
  translation: (key: string) => string;
  basePath: string;
};

const navLinksClassnames = `border-2 border-transparent !px-0 max-md:py-[5px]  max-md:!text-2xl md:!px-[10px]`;

const NavLinks = ({ translation: t, basePath }: NavLinksProps) => {
  return (
    <>
      <li>
        <Link
          className={classnames(
            Button.classes.baseClassName,
            Button.classes.classNames.tertiary_white,
            Button.classes.classNames.small,
            navLinksClassnames,
          )}
          href={`${basePath}/pricing`}
        >
          {t("header.pricing")}
        </Link>
      </li>
      <li>
        <Link
          className={classnames(
            Button.classes.baseClassName,
            Button.classes.classNames.tertiary_white,
            Button.classes.classNames.small,
            navLinksClassnames,
          )}
          href={`${basePath}/download`}
        >
          {t("header.download_app")}
        </Link>
      </li>
      <li>
        <Link
          className={classnames(
            Button.classes.baseClassName,
            Button.classes.classNames.tertiary_white,
            Button.classes.classNames.small,
            navLinksClassnames,
          )}
          href={`${basePath}/contact-us`}
        >
          {t("header.support")}
        </Link>
      </li>
    </>
  );
};

export default NavLinks;
