"use client";
import { Footer } from "@submix/next-components";
import { useTranslation } from "../i18n/client";

const ClientFooterWrapper = ({
  lang,
  basePath,
}: {
  lang: string;
  basePath: string;
}) => {
  const { t } = useTranslation(lang, "common");

  return <Footer translation={t} basePath={basePath} />;
};

export default ClientFooterWrapper;
