import { BaseSvgProps } from "./types";
const SvgMastercardIcon = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M12.54 10.236a9.436 9.436 0 0 1 3.604-7.424A9.43 9.43 0 0 0 2.316 5.226 9.45 9.45 0 0 0 6.19 18.731a9.426 9.426 0 0 0 9.954-1.07 9.425 9.425 0 0 1-3.603-7.425ZM31.41 10.236a9.45 9.45 0 0 1-5.312 8.495 9.426 9.426 0 0 1-9.953-1.07 9.442 9.442 0 0 0 3.603-7.425 9.453 9.453 0 0 0-3.604-7.424 9.43 9.43 0 0 1 13.828 2.414 9.451 9.451 0 0 1 1.437 5.01ZM30.51 16.089v-.304h.122v-.062h-.312v.062h.123v.304h.067Zm.605 0v-.367h-.095l-.11.252-.11-.252h-.096v.367h.068v-.277l.103.239h.07l.103-.24v.278h.067Z"
      fill="#8E8E8E"
    />
  </svg>
);
export default SvgMastercardIcon;
