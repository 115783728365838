import { BaseSvgProps } from "./types";
const SvgTwitterIconSmall = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M.75 15.38c2.207.125 4.136-.391 6.018-1.757-1.9-.325-3.219-1.194-3.935-2.99.554-.076 1.04.067 1.614-.162-1.872-.822-3.086-2.054-3.133-4.184.592.047 1.04.439 1.748.382C1.342 4.987.817 3.153 1.896.928 3.692 2.99 5.794 4.395 8.364 5.054c.143.038.277.077.42.105.659.153 1.442.478 1.843.43.688-.086 0-.879.172-1.91.545-3.21 4.08-4.672 6.697-2.79.764.554 1.366.545 2.12.21.392-.171.793-.343 1.262-.544-.106.917-.813 1.423-1.376 2.102.64.143 1.156-.039 1.748-.23-.2.66-.697 1.032-1.146 1.395-.468.373-.65.764-.669 1.376-.287 9.256-10.804 16.411-17.997 10.718-.698-.554-.02 0-.688-.535Z"
      fill="#fff"
    />
  </svg>
);
export default SvgTwitterIconSmall;
