import type { Session } from "next-auth";
import React from "react";
import MyAccount from "./MyAccount";
import NavLinks from "./NavLinks";

import {
  FreeTrialNote,
  getFreeTrialRemainingDays,
} from "@submix/component-library";
import AuthAction from "./AuthAction";
import BurgerButton from "./BurgerButton";
import { HeaderTransComponent } from ".";

type NavItemsProps = {
  session: Session | null;
  hasActiveSubscription: boolean;
  translation: (key: string) => string;
  basePath: string;
  hideAuth?: boolean;
  plan: {
    planCode?: string;
    planCreatedAt?: string;
    trialPeriod?: number;
  };
  isLoadingPlanCode: boolean;
  Trans?: HeaderTransComponent;
};

const NavItems: React.FC<NavItemsProps> = ({
  session,
  hasActiveSubscription,
  translation,
  basePath,
  hideAuth,
  plan,
  isLoadingPlanCode,
  Trans,
}) => {
  const planCode = plan.planCode || "";

  const freeTrialRemainingDays =
    plan.planCreatedAt && plan.trialPeriod
      ? getFreeTrialRemainingDays(plan.planCreatedAt, plan.trialPeriod)
      : 0;

  return (
    <>
      <ul className="hidden items-center md:flex md:grow">
        <NavLinks translation={translation} basePath={basePath} />
        <li className="ml-auto flex gap-1.5">
          {!isLoadingPlanCode && freeTrialRemainingDays ? (
            <FreeTrialNote
              Trans={Trans}
              freeTrialRemainingDays={freeTrialRemainingDays}
            />
          ) : null}
          <MyAccount
            session={session}
            hasActiveSubscription={hasActiveSubscription}
            basePath={basePath}
            planCode={planCode}
            isLoadingPlanCode={isLoadingPlanCode}
          />
          {!hideAuth && !session && (
            <AuthAction session={session} translation={translation} />
          )}
        </li>
      </ul>
      <div className="flex justify-end gap-2.5 md:hidden">
        {!isLoadingPlanCode && freeTrialRemainingDays ? (
          <FreeTrialNote
            classNames="max-sm:hidden"
            Trans={Trans}
            freeTrialRemainingDays={freeTrialRemainingDays}
          />
        ) : null}
        <MyAccount
          session={session}
          hasActiveSubscription={hasActiveSubscription}
          basePath={basePath}
          planCode={planCode}
          isLoadingPlanCode={isLoadingPlanCode}
        />
        {!session && !hideAuth && (
          <AuthAction session={session} translation={translation} />
        )}
        <BurgerButton className="float-right h-screen !pb-0">
          <ul className="flex h-full flex-col gap-2.5 p-7">
            <NavLinks translation={translation} basePath={basePath} />
            {!isLoadingPlanCode && freeTrialRemainingDays ? (
              <FreeTrialNote
                Trans={Trans}
                freeTrialRemainingDays={freeTrialRemainingDays}
              />
            ) : null}
            <li className="flex gap-3 ">
              <MyAccount
                session={session}
                hasActiveSubscription={hasActiveSubscription}
                className="!ml-0 flex-row-reverse items-start justify-end"
                basePath={basePath}
                planCode={planCode}
                isLoadingPlanCode={isLoadingPlanCode}
              />
              {!hideAuth && !session && (
                <AuthAction
                  className="!ml-0 flex-row-reverse"
                  session={session}
                  translation={translation}
                />
              )}
            </li>
          </ul>
        </BurgerButton>
      </div>
    </>
  );
};

export default NavItems;
