"use client";
import React, { useEffect, useState } from "react";
import classnames from "classnames";

const burgerBaseClassNames = `
block
my-1.5
h-[2px]
w-6
bg-neutral-50
rounded-[25px]
transition-all
ease-in-out
duration-[0.25s]
`;

export type BurgerButtonProps = {
  children?: React.ReactNode;
  className?: string;
};

const BurgerButton: React.FC<BurgerButtonProps> = ({ children, className }) => {
  const [openNav, setOpenNav] = useState(false);
  const toggleNav = () => {
    setOpenNav((prevState) => !prevState);
  };

  useEffect(() => {
    if (openNav) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "unset";
  }, [openNav]);
  return (
    <>
      <div
        onClick={toggleNav}
        className={classnames(
          "fixed left-0 top-0 w-full bg-neutral-900 pb-4 md:static md:w-fit md:border-t-0 md:bg-transparent",
          {
            "invisible opacity-0 md:visible md:opacity-100": !openNav,
            "opacity-100 transition-opacity ": openNav,
          },
          className,
        )}
      >
        {children}
      </div>
      <button
        aria-label="open navigation"
        className="inline-block h-6 w-6 md:hidden"
        onClick={toggleNav}
      >
        <span
          className={classnames(
            burgerBaseClassNames,
            openNav && "translate-y-[10px] rotate-45",
          )}
        />
        <span
          className={classnames(
            burgerBaseClassNames,
            openNav && "translate-x-[-200%] opacity-0",
          )}
        />
        <span
          className={classnames(
            burgerBaseClassNames,
            openNav && "translate-y-[-6px] rotate-[-45deg]",
          )}
        />
      </button>
    </>
  );
};

export default BurgerButton;
