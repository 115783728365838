import { BaseSvgProps } from "./types";
const SvgInstagramIconSmall = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M10.004 6.038a3.968 3.968 0 0 0-3.966 3.966 3.968 3.968 0 0 0 3.966 3.966 3.968 3.968 0 0 0 3.966-3.966c-.008-2.184-1.782-3.966-3.966-3.966Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.467 19.814h13.066a3.278 3.278 0 0 0 3.28-3.28V3.466a3.289 3.289 0 0 0-3.28-3.28H3.467a3.289 3.289 0 0 0-3.28 3.28v13.066a3.289 3.289 0 0 0 3.28 3.28ZM16.024 4.8a.967.967 0 1 0 0-1.935.967.967 0 0 0 0 1.935Zm-6.02 10.24a5.006 5.006 0 0 1-3.563-1.474 5.005 5.005 0 0 1-1.475-3.563c0-1.346.524-2.612 1.475-3.563a5.006 5.006 0 0 1 3.563-1.475c1.346 0 2.612.524 3.563 1.475a5.006 5.006 0 0 1 1.475 3.563 5.005 5.005 0 0 1-1.475 3.563 5.02 5.02 0 0 1-3.563 1.475Z"
      fill="#fff"
    />
  </svg>
);
export default SvgInstagramIconSmall;
