import { BaseSvgProps } from "./types";
const SvgGrayLock = ({ width, height, className }: BaseSvgProps) => (
  <svg
    role="img"
    width={width || 16}
    height={height || 22}
    className={className}
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.76563 14.834C8.76563 15.3611 8.35313 15.7923 7.84896 15.7923C7.34479 15.7923 6.93229 15.3611 6.93229 14.834C6.93229 14.3069 7.34479 13.8757 7.84896 13.8757C8.35313 13.8757 8.76563 14.3069 8.76563 14.834ZM15.1823 8.12565V21.5423H8.77479L8.76563 17.5365C9.82896 17.1436 10.599 16.0894 10.599 14.834C10.599 13.2527 9.36146 11.959 7.84896 11.959C6.33646 11.959 5.09896 13.2527 5.09896 14.834C5.09896 16.0798 5.86896 17.134 6.93229 17.5365L6.94146 21.5423H0.515625V8.12565H3.26563V5.25065C3.26563 2.60565 5.31896 0.458984 7.84896 0.458984C10.379 0.458984 12.4323 2.60565 12.4323 5.25065V8.12565H15.1823ZM5.09896 8.12565H10.599V5.25065C10.599 3.6694 9.36146 2.37565 7.84896 2.37565C6.33646 2.37565 5.09896 3.6694 5.09896 5.25065V8.12565Z"
      fill="#8E8E8E"
    />
  </svg>
);
export default SvgGrayLock;
