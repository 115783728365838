import { BaseSvgProps } from "./types";
const SvgVisaIcon = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 55 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M35.551.236c-3.834 0-7.26 2.045-7.26 5.822 0 4.332 6.076 4.632 6.076 6.808 0 .916-1.02 1.736-2.764 1.736-2.474 0-4.323-1.146-4.323-1.146l-.792 3.812s2.13.968 4.96.968c4.191 0 7.49-2.145 7.49-5.987 0-4.577-6.102-4.868-6.102-6.887 0-.718.837-1.505 2.576-1.505 1.961 0 3.562.834 3.562.834l.774-3.682S38.007.236 35.55.236ZM.471.514.38 1.07s1.613.304 3.066.91c1.87.694 2.004 1.099 2.319 2.355L9.197 17.95h4.602L20.889.514h-4.592L11.741 12.37 9.883 2.32C9.712 1.17 8.848.514 7.79.514H.471Zm22.264 0L19.133 17.95h4.378L27.101.514h-4.366Zm24.42 0c-1.057 0-1.616.582-2.026 1.598L38.714 17.95h4.591l.889-2.64h5.593l.54 2.64h4.052L50.845.514h-3.69Zm.596 4.71 1.361 6.543h-3.646l2.285-6.542Z"
      fill="#8E8E8E"
    />
  </svg>
);
export default SvgVisaIcon;
