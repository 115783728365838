import { Header as LibHeader } from "@submix/component-library";
import Link from "next/link";
import type { Session } from "next-auth";
import NavItems from "./NavItems";

export type HeaderTransComponent = ({
  children,
  key,
}: {
  children?: string;
  key?: string;
  i18nKey: string;
  ns?: string;
  count?: number;
}) => JSX.Element;

type HeaderProps = {
  session: Session | null;
  translation: (key: string) => string;
  hasActiveSubscription: boolean;
  darkNav?: boolean;
  basePath?: string;
  noLinks?: boolean;
  hideAuth?: boolean;
  isLoadingPlanCode: boolean;
  plan: {
    planCode?: string;
    planCreatedAt?: string;
    trialPeriod?: number;
  };
  Trans?: HeaderTransComponent;
};

const Header = ({
  session,
  translation,
  hasActiveSubscription,
  darkNav = false,
  basePath = "",
  noLinks = false,
  hideAuth = false,
  isLoadingPlanCode,
  plan,
  Trans,
}: HeaderProps) => {
  const logoHref = !basePath || basePath === "" ? "/" : basePath;
  return (
    <LibHeader
      darkNav={darkNav}
      LinkComponent={Link}
      logoHref={logoHref}
      navItems={
        noLinks ? null : (
          <NavItems
            session={session}
            translation={translation}
            hasActiveSubscription={hasActiveSubscription}
            basePath={basePath}
            hideAuth={hideAuth}
            isLoadingPlanCode={isLoadingPlanCode}
            plan={plan}
            Trans={Trans}
          />
        )
      }
      maxWidth="max-w-[1440px]"
      className="[&>nav]:!h-[52px]"
    />
  );
};

export default Header;
