"use client";
import { Button, Loader } from "@submix/component-library";
import classNames from "classnames";
import classnames from "classnames";
import type { Session } from "next-auth";
import { signOut } from "next-auth/react";
import Link from "next/link";
import React from "react";

export type AuthActionProps = {
  loading?: boolean;
  onDark?: boolean;
  session: Session | null;
  translation: (key: string) => string;
  className?: string;
};

const AuthAction: React.FC<AuthActionProps> = ({
  loading = false,
  onDark = false,
  session,
  translation: t,
  className,
}) => {
  const handleLogOut = () => {
    signOut();
  };

  if (loading) {
    return (
      <div className=" w-[167px]">
        <Button
          size="small"
          variant="primary"
          color="green"
          className={classnames("float-right !w-[82px]")}
          disabled
        >
          <div className="flex w-full flex-row items-center justify-center">
            <Loader className="ml-2 max-h-5" onDark={onDark} />
          </div>
        </Button>
      </div>
    );
  }

  if (!session && !loading) {
    return (
      <div className={classNames(className, "ml-auto flex ")}>
        <Link
          className={classnames(
            "flex !w-fit items-center",
            Button.classes.baseClassName,
            Button.classes.classNames.small,
            Button.classes.classNames.tertiary_white,
            "border-2 border-transparent !px-[10px]",
          )}
          href="/auth/login"
        >
          {loading ? (
            <div className="flex w-full flex-row items-center justify-center">
              <Loader className=" ml-2 max-h-5" onDark={onDark} />
            </div>
          ) : (
            <span className="w-full">{t("header.login")}</span>
          )}
        </Link>
        {!loading && (
          <Link
            className={classnames(
              "flex !w-fit items-center",
              Button.classes.baseClassName,
              Button.classes.classNames.primary,
              Button.classes.classNames.small,
              Button.classes.classNames.primary_green,
            )}
            href="/auth/signup"
          >
            <span className="w-full">{t("header.signup")}</span>
          </Link>
        )}
      </div>
    );
  }

  return (
    <Button size="small" variant="primary" color="white" onClick={handleLogOut}>
      {t("header.sign_out")}
    </Button>
  );
};

export default AuthAction;
