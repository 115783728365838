import { BaseSvgProps } from "./types";
const SvgCbIcon = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 31 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 .483H.38V23.99H31V.483ZM28.793 8.02c.15.372.225.752.225 1.143 0 .385-.075.766-.23 1.147a2.723 2.723 0 0 1-.648.94c-.315.3-.658.512-1.033.63-.376.117-.888.178-1.531.178H16.9V6.266h8.68c.643 0 1.155.06 1.53.178.376.123.719.334 1.034.635.286.273.502.588.648.94Zm.225 7.71c0-.39-.075-.771-.225-1.147a2.743 2.743 0 0 0-.648-.945 2.693 2.693 0 0 0-1.034-.635c-.375-.122-.887-.178-1.53-.178H16.9v5.8h8.675c.643 0 1.155-.06 1.53-.178.376-.122.72-.329 1.034-.635.286-.272.502-.587.648-.944a2.9 2.9 0 0 0 .23-1.138Zm-12.897-3.676v.005-.005ZM9.025 5.894c4.23 0 6.92 2.415 7.096 6.159h-6.87v.77h6.87c-.168 3.748-2.831 6.178-7.096 6.178-4.26 0-7.106-2.459-7.106-6.553 0-3.968 2.738-6.554 7.106-6.554Z"
      fill="#8E8E8E"
    />
  </svg>
);
export default SvgCbIcon;
