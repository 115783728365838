import { BaseSvgProps } from "./types";
const SvgFacebookIconSmall = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M8.443 12.75v9H4.31v-9H.874V9.1h3.435V7.772c0-4.93 2.059-7.522 6.416-7.522 1.336 0 1.67.215 2.401.39v3.61c-.819-.144-1.05-.223-1.9-.223-1.01 0-1.55.286-2.043.85-.493.565-.74 1.543-.74 2.943v1.288h4.683l-1.256 3.65H8.443v-.009Z"
      fill="#fff"
    />
  </svg>
);
export default SvgFacebookIconSmall;
