import classnames from "classnames";
import { useMemo } from "react";
import { Typography } from "../Typography";

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 1) - hash);
  }
  return hash;
}

const getHSL = (str: string) => {
  const [h, s, l] = [
    ((hashCode(str) & 0xffffff) % 360) / 360,
    (Math.abs(hashCode(str)) % 25000) / 25000,
    (Math.abs(hashCode(str)) % 12500) / 12500,
  ];
  return [h, s, l] as const;
};

const getColor = (h: number, s: number, l: number) => {
  let hue = h * 360;
  // Avoid generating "flash green" (brand) color and anything similar to that
  if (hue >= 75 && hue <= 135) {
    hue = hue + 80;
  }
  // Saturation cca 80-90%
  // Lightness cca 45-50%
  return `hsl(${hue},${70 + 200 * s}%,${40 + 50 * l}%)`;
};

const Avatar = ({
  ownerName,
  className,
  plain,
}: {
  ownerName: string;
  className?: string;
  plain?: boolean;
}) => {
  const firstLetter = ownerName && ownerName.charAt(0).toUpperCase();

  const color = useMemo(
    () => (plain === true ? undefined : getColor(...getHSL(ownerName))),
    [ownerName, plain],
  );

  return (
    <div
      className={classnames(
        "flex h-7 w-7 shrink-0 items-center justify-center rounded-full ",
        className,
        {
          "bg-secondary": plain,
        },
      )}
      style={{ backgroundColor: color }}
    >
      <Typography.Body
        element="span"
        className={classnames("text-xs !font-bold !text-primary", {
          "!text-secondary": plain,
        })}
      >
        {firstLetter}
      </Typography.Body>
    </div>
  );
};

export { Avatar };
