import { BaseSvgProps } from "./types";
const SvgYoutubeIconSmall = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.91 17.09h16.18c2.166 0 3.919-1.905 3.91-4.27V5.18C24 2.825 22.256.91 20.09.91H3.91C1.752.91 0 2.815 0 5.18v7.64c0 2.355 1.744 4.27 3.91 4.27ZM9.437 4.955l6.807 3.925-6.807 3.926v-7.85Z"
      fill="#fff"
    />
  </svg>
);
export default SvgYoutubeIconSmall;
