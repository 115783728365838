import { ClockDurationTime, Typography } from "@submix/component-library";
import classnames from "classnames";

type FreeTrialNoteProps = {
  freeTrialRemainingDays: number;
  classNames?: string;
  Trans?: ({
    children,
    key,
  }: {
    children?: string;
    key?: string;
    i18nKey: string;
    ns?: string;
    count?: number;
  }) => JSX.Element;
};
export const FreeTrialNote = ({
  freeTrialRemainingDays,
  classNames,
  Trans,
}: FreeTrialNoteProps) => {
  if (freeTrialRemainingDays <= 0) return null;

  let remainingDays = freeTrialRemainingDays;
  if (freeTrialRemainingDays > 1) {
    remainingDays = Math.round(freeTrialRemainingDays);
  }

  return (
    <div className={classnames("flex items-center gap-1.5", classNames)}>
      <ClockDurationTime
        width={20}
        height={20}
        strokeWidth={2}
        stroke="#ffff"
      />
      <Typography.Body className="!text-sm !text-primary">
        {remainingDays > 0 && remainingDays < 1 ? (
          <>
            {Trans ? (
              <Trans i18nKey="free_trial_end_node_today" ns="common" />
            ) : (
              "Trial ends today"
            )}
          </>
        ) : null}
        {remainingDays >= 1 ? (
          <>
            {Trans ? (
              <Trans
                i18nKey="free_trial_end_node"
                ns="common"
                count={remainingDays}
              />
            ) : (
              `Trial ends in ${remainingDays} day${
                remainingDays !== 1 ? "s" : ""
              }`
            )}
          </>
        ) : null}
      </Typography.Body>
    </div>
  );
};
